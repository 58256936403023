@font-face {
  font-family: "Qanelas";
  src: url("./fonts/QanelasSoft-Regular.otf");
  src: url("./fonts/QanelasSoft-Regular.otf") format("opentype"),
    url("./fonts/QanelasSoft-Regular.otf") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Qanelas-SBold";
  src: url("./fonts/QanelasSoft-SemiBold.otf");
  src: url("./fonts/QanelasSoft-SemiBold.otf") format("opentype"),
    url("./fonts/QanelasSoft-SemiBold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Qanelas-Bold";
  src: url("./fonts/QanelasSoft-Bold.otf");
  src: url("./fonts/QanelasSoft-Bold.otf") format("opentype"),
    url("./fonts/QanelasSoft-Bold.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f3f3;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header-wrapper {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  .header-wrapper {
    flex-direction: column;
  }
}
.header-right {
  display: flex;
  align-items: center;
}
.flag-select.menu-flags:hover {
  cursor: pointer;
}
.flag-select__option {
  margin: 6px 0;
}
span.flag-select__option__label {
  font-size: .8em;
  padding-left: 6px;
}
.flag-select__options {
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 0;
  background: #fff;
  padding: 0 !important;
}
.flag-select__btn:after, .flag-select__btn[aria-expanded="true"]:after {
  margin-left: -2px;
  margin-top: 3px;
}

.page-title {
  font-family: "Qanelas-Bold", sans-serif;
  font-weight: 500;
  text-align: center;
}
@media screen and (max-width: 640px) {
  .page-title {
    font-size: 1.4rem;
  }
}
.page-title.long {
  font-size: 1.6rem;
  padding-bottom: 20px;
}
@media screen and (max-width: 640px) {
  .page-title.long {
    font-size: 1.2rem;
    max-width: 95%;
  }
}
.white-box {
  border-radius: 0 !important;
}
.btn-black {
  background-color: #000 !important;
  color: #fff !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  padding: 6px 25px !important;
  opacity: 1;
}
.btn-black.small {
  background-color: #000 !important;
  color: #fff !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  padding: 4px 20px !important;
  margin: 0 3px;
}
.btn-black.disabled {
  opacity: 0.2;
}
.btn-black.enabled {
  opacity: 1;
}
.btn-black > span,
.App-logoutButton-2 > span {
  font-family: "Qanelas-Bold", sans-serif;
  font-weight: 700;
  font-size: 16px;
  text-transform: none;
}
.App-logoutButton-2:hover {
  background-color: #000 !important;
}

